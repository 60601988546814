/* * {
    box-sizing: border-box;
  } */
  
  /* img {
    display: block;
    border: none;
  } */
  
  /* html {
    height: 100%;
  } */
  
  .image-wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  
  .wrapper {
    padding: 10px;
    width: 625px;
    height: 650px;
    margin: auto;
    overflow: hidden;
  }
  
  .viewport {
    right: 8px;
    position: relative;
    overflow: hidden;
    border-radius: 1.5em;
  }
  .viewport::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
    animation: lines 0.1s infinite;
    background-size: 100% 3px, 2px 100%;
  }
  
  .main-img {
    position: relative;
    animation: twitch 0.1s steps(3, end) infinite;
  }
  
  .line {
    pointer-events: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 100%;
    visibility: hidden;
    animation: slideBottom 4s ease-out infinite;
  }
  .line::after {
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: blue;
    opacity: .1;
  }
  .line-img {
    animation: twitch 0.1s steps(3, end) infinite;
  }
  .line-supwrap {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-10px);
    animation: slideTop 4s ease-out infinite;
  }
  .line-supwrap .ab {
    transform: translateX(10px);
  }
  
  .ab {
    position: absolute;
    display: none;
  }
  .ab-1 {
    width: 72px;
    height: 14px;
    background: linear-gradient(45deg, rgba(105, 153, 0, 0.2) 45%, rgba(255, 0, 0, 0.2));
    top: 0;
    right: 131px;
  }
  .ab-2 {
    width: 164px;
    height: 78px;
    background: rgba(255, 0, 0, 0.15);
    top: 68px;
    left: 80px;
    animation: ad-blink  1s infinite;
  }
  .ab-2::before, .ab-2::after {
    content: "";
    position: absolute;
    background-color: inherit;
  }
  .ab-2::before {
    height: 78px;
    width: 48px;
    top: 42px;
    left: 100%;
  }
  .ab-2::after {
    height: 42px;
    width: 64px;
    top: 100%;
    right: 0;
  }
  .ab-3 {
    width: 570px;
    height: 38px;
    background: linear-gradient(to right, rgba(255, 0, 0, 0.2), rgba(105, 153, 0, 0.3));
    right: 0;
    top: 120px;
    animation: one-blink  1.75s infinite;
  }
  .ab-4 {
    width: 415px;
    height: 78px;
    background: linear-gradient(to right, rgba(105, 153, 0, 0.15) 28%, rgba(0, 0, 255, 0.15));
    left: 0;
    top: 280px;
    animation: second-blink  2s infinite;
  }
  .ab-5 {
    width: 240px;
    height: 10px;
    background: linear-gradient(to right, rgba(255, 105, 180, 0.2) 10%, rgba(105, 153, 0, 0.3));
    left: 0;
    bottom: 0;
  }
  
  #check:checked + .viewport .ab {
    display: block;
  }
  
  @keyframes slideBottom {
    0% {
      top: 0px;
      visibility: visible;
    }
    100% {
      top: 746px;
    }
  }
  @keyframes slideTop {
    0% {
      top: 0px;
    }
    100% {
      top: -746px;
    }
  }
  @keyframes twitch {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-0.5px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @keyframes one-blink {
    0%, 69% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    85%, 94% {
      opacity: 0;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes second-blink {
    0%, 95% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes ad-blink {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    10%, 25% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    35%, 100% {
      opacity: 0;
    }
  }
  @keyframes blink {
    0% {
      opacity: 1;
    }
    1% {
      opacity: 0;
    }
    2% {
      opacity: 1;
    }
    4% {
      opacity: 0;
    }
    6% {
      opacity: 1;
    }
  }
  @keyframes lines {
    0% {
      background-image: repeating-linear-gradient(0deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 2px, rgba(0, 0, 0, 0.3) 3px);
    }
    50% {
      background-image: repeating-linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0) 3px);
    }
    100% {
      background-image: repeating-linear-gradient(0deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 2px, rgba(0, 0, 0, 0.3) 3px);
    }
  }
  
  @media screen and (max-width: 625px){
    .wrapper {
        width: 375px;
        height: 400px;
        margin: auto;
        overflow: hidden;
      }
    
  }